<template>
  <div class="remote-upgrade">
    <Index v-if="!showDetail" @detail="handleDetail"></Index>
    <Detail v-else :schoolKey="schoolKey" @back="handleBack"></Detail>
  </div>
</template>

<script>
import Index from './components/index/index.vue';
import Detail from './components/detail/detail.vue';

export default {
  name: 'remote-upgrade',
  components: {
    Index,
    Detail,
  },
  data() {
    return {
      showDetail: true,
      schoolKey: 'qlcloud',
    };
  },
  methods: {
    handleDetail(schoolKey) {
      this.showDetail = true;
      this.schoolKey = schoolKey;
    },
    handleBack() {
      this.showDetail = false;
      this.schoolKey = this.$options.data().schoolKey; // reset
    },
  },
};
</script>

<style lang="scss" scoped></style>
