<template>
  <div class="remote-upgrade">
    <div class="remote-upgrade-header">
      <ul class="remote-upgrade-tag">
        <li
          class="remote-upgrade-tag-item"
          :class="{ 'remote-upgrade-tag-item-active': isLocal === PUBLIC_SERVER }"
          @click="handleTag(PUBLIC_SERVER)"
        >
          全公共服务器部
        </li>
        <li
          class="remote-upgrade-tag-item"
          :class="{ 'remote-upgrade-tag-item-active': isLocal === LOCAL_SERVER }"
          @click="handleTag(LOCAL_SERVER)"
        >
          本地服务器
        </li>
      </ul>
      <el-input
        v-model.trim="table.schoolNameFuzzy"
        size="small"
        class="mg-r-40"
        placeholder="请输入学校名称查找"
        :style="{ width: '280px' }"
        @change="handleSearch"
      >
        <i class="cursor-pointer pd-t-9 pd-r-9 pd-b-9 pd-r-9 el-icon-search" slot="suffix" @click="handleSearch"> </i>
      </el-input>
      <el-button v-if="operation !== 4" type="primary" size="small" style="height: 32px" @click="handleMassUpgrate"
        >批量升级</el-button
      >
      <p v-else>已是最新</p>
    </div>
    <div class="remote-upgrade-container">
      <div v-if="isLocal === PUBLIC_SERVER" class="flex mg-b-24">
        <div class="flex flex-1 items-center">
          <p class="text-F1">后台版本：</p>
          <p style="color: #6d7171">{{ javacodeVersion.versionName }}</p>
          <p class="mg-l-16 mg-r-16 text-F1">|</p>
          <p class="text-F1">版本说明：</p>
          <p style="color: #6d7171">{{ javacodeVersion.description }}</p>
        </div>
      </div>
      <!-- table -->
      <el-table
        :data="table.list"
        class="remote-upgrade-table mg-b-50"
        style="width: 100%"
        :row-key="
          (row) => {
            return row.schoolId;
          }
        "
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :reserve-selection="true" width="55"> </el-table-column>
        <el-table-column label="学校">
          <template slot-scope="scope">
            <span class="school-name" @click="handleSwitchSchool(scope.row.schoolKey)">{{ scope.row.schoolName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="青小鹿盒子版本">
          <template slot-scope="scope">
            <span v-html="renderBoxVersionsHTML(scope.row)"></span>
          </template>
        </el-table-column>
        <el-table-column :label="isLocal === PUBLIC_SERVER ? '状态' : '盒子状态'">
          <template slot-scope="scope">
            <!-- null. 未初始化 8. 未初始化  -->
            <p v-if="[null, 8].includes(scope.row.classStatus)">未初始化</p>
            <!-- 1. 升级成功  -->
            <p v-if="scope.row.classStatus === 1">升级成功</p>
            <!-- 2. 升级中  -->
            <p v-if="scope.row.classStatus === 2">升级中</p>
            <!-- 5. 不兼容 6. 待升级  -->
            <p v-if="[5, 6].includes(scope.row.classStatus)">待升级</p>
            <!-- 7. 已是最新 -->
            <p v-if="scope.row.classStatus === 7">已是最新</p>
            <!-- 0. 升级失败  -->
            <el-popover v-if="scope.row.classStatus === 0" placement="top-start" trigger="hover">
              <div class="flex" v-for="item in scope.row.classErrorMessage" :key="item">
                <div class="title">{{ item.place }}：</div>
                <div class="content">{{ item.message }}</div>
              </div>
              <p slot="reference">升级失败</p>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column v-if="isLocal === LOCAL_SERVER" label="后台版本">
          <template slot-scope="scope">
            <span>{{ scope.row.versionName }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="isLocal === LOCAL_SERVER" label="后台状态">
          <template slot-scope="scope">
            <!-- null. 未初始化 8. 未初始化  -->
            <p v-if="[null, 8].includes(scope.row.schoolStatus)">未初始化</p>
            <!-- 1. 升级成功  -->
            <p v-if="scope.row.schoolStatus === 1">升级成功</p>
            <!-- 2. 升级中  -->
            <p v-if="scope.row.schoolStatus === 2">升级中</p>
            <!-- 5. 不兼容 6. 待升级  -->
            <p v-if="[5, 6].includes(scope.row.schoolStatus)">待升级</p>
            <!-- 7. 已是最新 -->
            <p v-if="scope.row.schoolStatus === 7">已是最新</p>
            <!-- 0. 升级失败  -->
            <el-popover v-if="scope.row.schoolStatus === 0" placement="top-start" trigger="hover">
              <p>{{ schoolItem.message ? schoolItem.message : '暂无内容' }}</p>
              <p slot="reference">升级失败</p>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="操作">
          <template slot-scope="scope">
            <!-- null. 未初始化  -->
            <p v-if="scope.row.operation === null">未初始化</p>
            <!-- 0. 暂无操作  -->
            <p v-if="scope.row.operation === 0">--</p>
            <!-- 2. 升级中  -->
            <p v-if="scope.row.operation === 2">升级中</p>
            <!-- 4. 已是最新  -->
            <p v-if="scope.row.operation === 4">已是最新</p>
            <!-- 1.修改升级 3.升级 5.升级失败 -->
            <el-button
              v-if="[1, 3, 5].includes(scope.row.operation)"
              type="text"
              size="small"
              @click="handleSingleUpgrate(scope.row, scope.$index)"
              >{{ scope.row.operation === 1 ? '修改' : '升级' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <qlPagination
        :current-page.sync="table.pageIndex"
        :page-size="table.pageSize"
        :total="table.total"
        @size-change="() => getPageJSON()"
        @current-change="() => getPageJSON()"
      ></qlPagination>
    </div>
    <MassUpgradeDialog
      :visible="massUpgradeDialogVisible"
      :isLocal="isLocal"
      :schoolVersionCode="massUpgradeDialogSchoolVersionCode"
      @success="massUpgradeDialogVisible = false"
      @close="massUpgradeDialogVisible = false"
    ></MassUpgradeDialog>
    <SingleUpgradeDialog :visible="singleUpgradeDialogVisible"></SingleUpgradeDialog>
  </div>
</template>

<script>
/**
 * 远程升级 index
 */
import { getListRegisterSchool } from '@/api/back-stage';

import { PUBLIC_SERVER, LOCAL_SERVER } from '../../constance';
import { Table, TableColumn, Input, Button, Popover } from 'element-ui';
import qlPagination from '@/components/qlPagination';
import MassUpgradeDialog from '../massUpgradeDialog.vue';
import SingleUpgradeDialog from '../singleUpgradeDialog.vue';

export default {
  name: 'remote-upgrade-index',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Popover.name]: Popover,
    qlPagination,
    MassUpgradeDialog,
    SingleUpgradeDialog,
  },
  data() {
    return {
      // const
      PUBLIC_SERVER,
      LOCAL_SERVER,

      isLocal: Number(sessionStorage.getItem('remote_upgrade_is_local')),
      operation: undefined,

      javacodeVersion: {
        versionName: '',
        description: '',
      },
      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        schoolNameFuzzy: '',
      },

      multipleSelection: [],

      // massUpgradeDialog
      massUpgradeDialogVisible: false,
      massUpgradeDialogSchoolVersionCode: '',

      // singleUpgradeDialog
      singleUpgradeDialogVisible: false,
    };
  },
  methods: {
    // handle 切换tag
    handleTag(val) {
      this.isLocal = val;
      sessionStorage.setItem('remote_upgrade_is_local', val);
      this.handleSearch();
    },
    // handle 搜索
    handleSearch() {
      this.table.pageIndex = 1;
      this.getPageJSON();
    },
    // handle 点击学校
    handleSwitchSchool(schoolKey) {
      this.$emit('detail', schoolKey);
    },
    handleSelectionChange(val) {
      console.log(`handleSelectionChange`);
      console.log(val);
      this.multipleSelection = val;
    },
    // handle 单个升级
    handleSingleUpgrate(school) {
      console.log(school);
    },
    // handle 批量升级
    handleMassUpgrate() {
      this.massUpgradeDialogVisible = true;
    },
    // http
    getPageJSON() {
      let params = {
        isLocal: this.isLocal,
        pageIndex: this.table.pageIndex,
        pageSize: this.table.pageSize,
      };

      if (this.table.schoolNameFuzzy !== '') {
        params.schoolNameFuzzy = this.table.schoolNameFuzzy;
      }

      getListRegisterSchool(params).then(({ result }) => {
        // console.log(result);

        this.table.total = result.registerSchools.recordCount;
        this.operation = result.operation;
        this.javacodeVersion = result.javacodeVersion;

        let data = result.registerSchools.data;

        // 数据处理
        data.map((schoolItem) => {
          let versionNames = new Set();
          let versionList = []; // 青鹿盒子版本
          let classErrorMessage = []; // 错误数据

          schoolItem?.registerClasses?.forEach((classItem) => {
            if (!versionNames.has(classItem.versionName)) {
              versionNames.add(classItem.versionName);
              versionList.push({
                versionName: classItem.versionName,
                status: classItem.status,
                warning: classItem.warning,
              });
            }

            if (classItem.status === 0) {
              classErrorMessage.push({
                place: classItem.place,
                message: classItem.message,
              });
            }
          });

          schoolItem.versionList = versionList.sort((a, b) => b.warning - a.warning);
          schoolItem.classErrorMessage = classErrorMessage;
        });

        this.table.list = data;
      });
    },
    initPage() {
      this.getPageJSON();
    },
    // util render
    renderBoxVersionsHTML(schoolItem) {
      let html = '';

      schoolItem.versionList.forEach((item, index) => {
        if (item.versionName === null) {
          return;
        }
        if (item.warning === 1) {
          html += `<span style="color: #F69036">${item.versionName}</span>`;
        } else {
          html += `<span>${item.versionName}</span>`;
        }
        if (index !== schoolItem.versionList.length - 1) {
          html += `<span style="width: 20px;display: inline-block;">, </span>`;
        }
      });

      if (schoolItem.versionList.length === 0) {
        html = `--`;
      }

      return html;
    },
  },
  created() {
    this.initPage();

    console.log(sessionStorage.getItem('remote_upgrade_is_local'));

    if (sessionStorage.getItem('remote_upgrade_is_local') === null) {
      sessionStorage.setItem('remote_upgrade_is_local', PUBLIC_SERVER);
    }
  },
};
</script>

<style lang="scss" src="./index.scss" scoped></style>
