export const LOCAL_SERVER = 0;
export const PUBLIC_SERVER = 1;

export const CLASS_STATUS = {
  FAIL: 0, // 失败
  SUCCESS: 1, // 成功
  UPGRADING: 2, // 升级中
  NOTCOMPATIBLE: 5, // 不兼容
  TOBEUPGRADE: 6, // 待升级
  UPTODATE: 7, // 已是最新
  UNINITIALIZED: 8, // 未初始化
};

export const SCHOOL_STATUS = {
  FAIL: 0, // 失败
  SUCCESS: 1, // 成功
  UPGRADING: 2, // 升级中
  NOTCOMPATIBLE: 5, // 不兼容
  TOBEUPGRADE: 6, // 待升级
  UPTODATE: 7, // 已是最新
  UNINITIALIZED: 8, // 未初始化
};
